const appConfig = {
  "name": "covidpass",
  "version": "2.0.0",
  "stage": "develop",
  "regions": [
    {
      "name": "us-east-1",
      "isPrimaryRegion": true,
      "deployThisBuild": true,
      "regionDomain": "get.atlas-config-dev.mit.edu",
      "amplify": {
        "userPoolId": "us-east-1_14cFcpuS8",
        "userPoolWebClientId": "7jlcnjvga70arn8ie4oave1u9v",
        "oauth": {
          "domain": "atlas-auth-dev.mit.edu",
          "scope": [
            "openid",
            "profile",
            "covid19/user",
            "digital-id/user",
            "digital-id/search"
          ],
          "redirectSignIn": "https://covidpass-dev.mit.edu",
          "redirectSignOut": "https://covidpass-dev.mit.edu/logout",
          "responseType": "token",
          "options": {
            "AdvancedSecurityDataCollectionFlag": false
          }
        }
      },
      "signInProvider": "Touchstone",
      "api": {
        "domain": "api-dev.mit.edu",
        "paths": {
          "covid19": "covid19-v1",
          "digital-id": "digital-id-v1",
          "pass": "pass-v1",
          "medical": "medical-v1",
          "vaccine": "vaccine-v1"
        }
      }
    },
    {
      "name": "us-west-1",
      "isPrimaryRegion": false,
      "deployThisBuild": true,
      "regionDomain": "get.atlas-config-dev.mit.edu",
      "amplify": {
        "userPoolId": "us-west-1_zBZDEjiKC",
        "userPoolWebClientId": "17k6ke7j5etjlcsnkq4586j8l2",
        "oauth": {
          "domain": "atlas-auth-us-west-dev.mit.edu",
          "scope": [
            "openid",
            "profile",
            "covid19/user",
            "digital-id/user",
            "digital-id/search"
          ],
          "redirectSignIn": "https://covidpass-dev.mit.edu",
          "redirectSignOut": "https://covidpass-dev.mit.edu/logout",
          "responseType": "token",
          "options": {
            "AdvancedSecurityDataCollectionFlag": false
          }
        }
      },
      "signInProvider": "Touchstone",
      "api": {
        "domain": "api-us-west-dev.mit.edu",
        "paths": {
          "covid19": "covid19-v1",
          "digital-id": "digital-id-v1",
          "pass": "pass-v1",
          "medical": "medical-v1",
          "vaccine": "vaccine-v1"
        }
      }
    }
  ]
};
export default appConfig;